<template>
  <div class="visual" id="visual">
    <v-img class="visual__background" :src="img" cover />
    <h1 class="visual__title">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: {
    // 画像パス
    img: {
      type: String,
      required: true
    },
    // タイトル
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.visual {
  position: relative;
  width: 100vw;
  height: 420px;
  &__background {
    width: 100%;
    height: 100%;
  }
  &__title {
    position: absolute;
    right: 32px;
    bottom: 28px;
    font-size: 3.5rem;
    font-weight: normal;
    color: $white_color;
  }
}
</style>
