<template>
  <div class="slideshow">
    <flickity class="slideshow__items"
              :options="options"  @init="api = $event.flickityApi">
      <img class="slideshow__items__img" v-for="img in imgs" :key="img" :src="img" cover />
    </flickity>
    <!-- <v-btn class="slideshow__btn--left" @click="previous()" icon :ripple="false">
      <v-icon class="slideshow__btn__icon">arrow_back_ios</v-icon></v-btn> -->
    <!-- <v-btn class="slideshow__btn--right" @click="next()" icon :ripple="false">
      <v-icon class="slideshow__btn__icon">arrow_forward_ios</v-icon></v-btn> -->
  </div>
</template>

<script>
import Flickity from 'vue-flickity-2'
import 'vue-flickity-2/dist/vue-flickity-2.css'

export default {
  components: { Flickity },
  props: {
    // 画像のパス一覧
    imgs: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      /**
       * flickityのオプション
       * @see https://flickity.metafizzy.co/options.html
       */
      options: {
        initialIndex: 1,
        freeScroll: true,
        prevNextButtons: false,
        wrapAround: true,
        autoPlay: 3000
      },
      // flickityの関数
      api: {}
    }
  },
  methods: {
    /**
     * 次へ
     */
    next () {
      this.api.next()
    },
    /**
     * 前へ
     */
    previous () {
      this.api.previous()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.slideshow {
  position: relative;
  width: 100vw;
  &__items {
    &__img {
      display: block;
      min-width: 130px;
      max-width: 100%;
      height: 180px;
      margin-right: 15px;
      background-color: rgba($color: $blue_color, $alpha: 0.3);
    }
  }
  &__btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0%, -50%);
    -ms-transform: translate(0%, -50%);
    transform: translate(0, -50%);
    &.theme--light.v-btn.v-btn--icon {
      color: $blue_color;
    }
    &::before {
      background-color: transparent;
    }
    &--left {
      @extend .slideshow__btn;
      left: 10px;
    }
    &--right {
      @extend .slideshow__btn;
      right: 10px;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/color.scss';

.flickity-page-dots .dot {
  background-color: transparent;
  border: 1px solid $blue_color;
  opacity: 1;
}

.flickity-page-dots .dot.is-selected {
  background-color: $blue_color;
}
</style>
