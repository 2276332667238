<template>
  <div class="description">
    <p class="description__main">{{ main }}</p>
    <p class="description__sub">{{ sub }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // 説明文：メイン
    main: {
      type: String,
      required: true
    },
    // 説明文：サブ
    sub: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.description {
  width: 293px;
  max-width: 80%;
  margin: 0 auto;
  &__main {
    margin: 0;
    font-size: 1.3rem;
    white-space: pre-wrap;
  }
  &__sub {
    margin: 25px 0 0;
    white-space: pre-wrap;
  }
}
</style>
