<template>
  <a class="contact" :href="url"
     target="_blank" rel="noopener noreferrer">問い合わせ</a>
</template>

<script>
export default {
  props: {
    // リクルートかどうか
    isRecruit: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    /**
     * @return {Boolean} お問い合わせURL
     */
    url () {
      return this.isRecruit ? 'https://docs.google.com/forms/d/e/1FAIpQLSccmuT2QdV669GNvRgM8wJ8evB9oJ1BOHvvvlgQdA0RYA1dKg/viewform' : 'https://docs.google.com/forms/d/e/1FAIpQLSeW1JiNP1NiKgTnnMxFXwSQ9bXZzgSAbh6fVgrYn072Ua85ow/viewform?usp=sf_link'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.contact {
  display: block;
  width: 155px;
  height: 50px;
  margin: 125px auto 120px auto;
  font-size: 1.5rem;
  line-height: 5rem;
  color: $white_color !important;
  text-align: center;
  background-color: $blue_color;
  border-radius: 40px;
}
</style>
